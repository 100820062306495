/* Provide sufficient contrast against white background */
a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}
.controlGroupsNavigation {
	padding-top: 0;
	text-align: right;
	position: fixed;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100px;
	background: rgba(255, 255, 255, 0.8);
}

.dropdown-menu {
	margin-right: -5rem !important;
}

.mainContainer {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 100px;
}

div[role="menubar"] {
	padding-left: 0px;
}

/*LanguageSelector*/
.languageSelector {
	margin-right: 0px !important;
	margin-left: auto;
}

.languageSelector div[role="menu"] {
	margin-left: -1rem !important;
	min-width: 7rem;
}

div.box {
	height: 10em;
	position: relative;
	display: flex;
	align-content: center;
}

div.box .centered {
	min-width: 100px;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}

/* PepolePicker*/
.ms-BasePicker-text {
	min-width: 0px !important;
}
